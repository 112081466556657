/*  */
.c-pointer {
  cursor: pointer;
}
.bg-white {
  background-color: #ffffff;
}
.bg-mute {
  background-color: #f6eeee;
}
.bg-dark {
  background-color: #000000;
}
.bg-dark-2 {
  background-color: #2b3990;
}
.bg-secondary {
  background-color: #ffc000;
}
.bg-prime {
  background-color: #17223b;
}
.text-xs {
  font-size: 12px;
}
.text-sm {
  font-size: 15px;
}
.text-center {
  text-align: center;
}
.text-danger {
  color: red !important;
}
.text-white {
  color: #ffffff;
}
.text-dark {
  color: #2b3990;
}
.text-mute {
  color: #ccc;
}
.text-prime {
  color: #17223b;
}
.line-height-0 {
  line-height: 25px;
}
.line-height-quickLink {
  line-height: 22px;
}
/*  */
.border-b {
  border-bottom: 1px solid #ccc;
}
.border-mute {
  border: 1px solid #ccc !important;
}
.border-b-mute {
  border-bottom: 1px solid #f6eeee;
}
.border-dashed {
  border: 1px dashed #ccc;
  transition: all 0.3s ease;
}
.border-dashed-double {
  border: 2px dashed red;
  transition: all 0.3s ease;
}
.border-dashed:hover {
  border-color: #2b3990;
}
.border {
  border: 1px solid #ccc;
}
.border-dark-sm {
  border: 1px solid #2b3990 !important;
}
.border-dark {
  border: 2px solid #2b3990 !important;
}
.rounded-5 {
  border-radius: 5px !important;
}
.rounded-10 {
  border-radius: 10px !important;
}
.rounded-circle {
  border-radius: 50%;
}
/*  */
.ms-3 {
  margin-left: 10px;
}
.ms-4 {
  margin-left: 15px;
}
.ms-5 {
  margin-left: 18px;
}
.me-3 {
  margin-right: 10px;
}
.me-4 {
  margin-right: 15px;
}
.me-5 {
  margin-right: 18px;
}
.mb-2 {
  margin-bottom: 7px;
}
.mb-3 {
  margin-bottom: 12px;
}
.mb-4 {
  margin-bottom: 15px;
}
.mb-5 {
  margin-bottom: 20px;
}
.mb-7 {
  margin-bottom: 30px;
}
.my-3 {
  margin: 10px auto;
}
.my-4 {
  margin: 15px auto;
}
.my-5 {
  margin: 20px auto;
}
.mt-5 {
  margin-top: 20px;
}
.mt-4 {
  margin-top: 15px;
}
.mt-3 {
  margin-top: 12px;
}
.mt-2 {
  margin-top: 10px;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin: auto auto;
}
.py-1 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.py-2 {
  padding-top: 7px;
  padding-bottom: 7px;
}
.ps-2 {
  padding-left: 5px;
}
.pe-2 {
  padding-right: 5px;
}
.p-2 {
  padding: 6px;
}
.p-1 {
  padding: 5px;
}
.p-0 {
  padding: 0;
}
.pb-0 {
  padding-bottom: 0 !important;
}
.px-2 {
  padding-left: 6px;
  padding-right: 6px;
}
.px-3 {
  padding-left: 10px;
  padding-right: 10px;
}
.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}
.p-3 {
  padding: 12px;
}
.p-4 {
  padding: 16px;
}
.py-4 {
  padding-top: 15px;
  padding-bottom: 15px;
}
/*  */
.fw-5 {
  font-weight: 500;
}
.fw-6 {
  font-weight: 600;
}
.fw-7 {
  font-weight: 700;
}

.fs-1 {
  font-size: 30px;
}
.fs-2 {
  font-size: 25px;
}
.fs-3 {
  font-size: 22px;
}
.fs-4 {
  font-size: 18px;
}
.fs-5 {
  font-size: 16px;
}
.fs-sm {
  font-size: 14px;
}

/*  */
.h-100 {
  height: 100%;
}
.h-inherit {
  height: inherit;
}
.w-100 {
  width: 100%;
}
/*  */
.tr-03 {
  transition: all 0.3s ease;
}
.shadow-sm-2 {
  box-shadow: 0 1px 1px rgba(180, 179, 179, 0.12),
    0 1px 1px rgba(139, 139, 139, 0.24);
}
.shadow-sm {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
/*  */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.jodit-wysiwyg ul,
.jodit-wysiwyg ol {
  margin-left: 20px !important;
  padding-left: 20px !important;
}
/*  */

.flex {
  display: flex;
  flex-wrap: wrap;
}
.aic {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.justify-around {
  justify-content: space-around;
}

.wrap {
  flex-wrap: wrap;
}
.flex-justify-between-no-gap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.flex-justify-between {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}
.g-3 {
  gap: 10px;
}
.w-200 {
  width: 200px;
}
.padding-24 {
  padding: 24px;
}

.ant-layout-content {
  max-height: 87vh;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}

.ant-layout-content::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit-based browsers */
}
.card {
  border: 1px solid #71cbe9;
  border-radius: 10px;
  padding: 15px;
}
.object-cover {
  object-fit: cover;
}
.h-130 {
  height: 130px;
}
.w-130 {
  width: 130px;
}
.w-25-p {
  flex: 1 1 25%;
}

@media (max-width: 991px) {
  .w-25-p {
    flex: 1 1 50%;
  }
}
@media (max-width: 768px) {
  .w-25-p {
    flex: 1 1 100%;
  }
  .padding-24 {
    padding: 12px;
  }
  h1 {
    line-height: normal !important;
  }
}
