/* SalarySlip.css */

.salary-slip-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #f0f2f5;
}

.salary-slip {
  width: 800px;
  background-color: #ffffff;
  padding: 30px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
}

.company-logo {
  width: 150px;
  height: auto;
}

.company-name {
  margin: 0;
}

.info-item {
  margin-bottom: 8px;
}

@media print {
  .salary-slip-container {
    padding: 0;
  }

  .salary-slip {
    width: 100%; /* Let it adjust dynamically for PDF */
    padding: 0;
  }

  .ant-btn {
    display: none; /* Hide buttons in the PDF */
  }

  .company-logo {
    width: 120px; /* Reduce logo size if needed */
  }
}
