* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mukta", sans-serif;
  font-size: 16px;
  /* color: #17223b; */
}

.navbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-image: url(./assets/navbg.svg) !important;
  background-size: cover !important; */
}
.multi-image-remove-icon {
  position: absolute;
  z-index: 1000;
  top: 0;
  right: 0;
  height: auto;
  border-radius: 4px;
  background-color: red;
  border-color: red;
  transition: all 0.2s ease;
}
.multi-image-remove-icon:hover {
  background-color: rgb(236, 87, 87) !important;
  border-color: rgb(236, 87, 87) !important;
}
.multi-image-remove-icon svg {
  font-size: 20px;
  color: white;
  font-weight: 700;
}
.breadcrumb {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #f5f5f5;
  padding: 15px;
  padding-top: 10px;
  transition: padding 0.2s ease; /* Smooth transition for padding */
}

.breadcrumb.sticky {
  padding: 8px 15px 8px 15px;
}

.flex-justify-between h1 {
  transition: line-height 0.2s ease; /* Smooth transition for line-height */
}
.lh-1 {
  line-height: 1;
}

.demo-logo-vertical {
  height: 70px;
  padding: 8px;
  /* background: #000; */
  /* border: 1px solid #0b7684; */
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.demo-logo-vertical img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.signin-page {
  height: 100vh;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* background-image: url(/Cloudy.svg); */
  background-size: cover;
}
.error-section {
  border: 1px solid red;
  /* box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); */
  border-radius: 10px;
  padding: 1rem;
  min-width: 350px;
}
/* #222121 */
.signin-card {
  border: 1px solid #ccc;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  border-radius: 10px;
  padding: 1rem;
  min-width: 350px;
  background-color: #fff;
}

/* Default styling for the responsive row */
.responsive-row .ant-col {
  padding: 0 10px;
}

.custom-table-row {
  height: 75px;
}
.ant-menu-item {
  margin-bottom: 10px;
}

/* modifying sidebar  */
.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical,
.ant-menu-light > .ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none !important;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-1gwfwyx).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td {
  font-size: 16px !important;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-tabs .ant-tabs-content {
  height: 100%;
}

:where(.css-dev-only-do-not-override-1gwfwyx).ant-tabs .ant-tabs-tabpane {
  height: 37vh;
}

.image-upload-input-wrap input {
  width: 90% !important;
}

.image-upload
  :where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
:where(
    .css-dev-only-do-not-override-1gwfwyx
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 100%;
  height: 100%;
}
.image-upload:hover .im-title {
  color: #2b3990;
}
.im-title {
  transition: all 0.3s ease-in-out;
}
.full-scr-icon {
  transition: all 0.2s ease;
}
.full-scr-icon:hover {
  scale: 1.1;
}
.image-preview-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.image-preview-grid
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-card
  .ant-card-actions {
  background: transparent;
  border: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.image-preview-grid .anticon-delete svg {
  color: transparent;
  transition: all 0.2s ease;
}
.image-preview-grid .anticon-delete svg:hover {
  color: red !important;
}

.image-preview-grid .ant-card:hover .anticon-delete svg {
  color: white;
}
.custom-modal .ant-modal-content {
  /* height: 60vh; */
  display: flex;
  flex-direction: column;
}

.custom-modal .ant-tabs-content-holder {
  flex: 1;
  overflow: auto; /* Ensure content scrolls if it overflows */
}

.approve-btn {
  color: #2b3990;
  transition: all 0.2s ease;
  cursor: pointer;
}
.approve-btn:hover {
  text-decoration: underline;
}

/*  */
.nav-user-menu:hover {
  border-color: #8d99b4;
}
.nav-user-img {
  height: 40px;
  width: 40px;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-user-img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-image-section
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select,
.ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload.ant-upload-select {
  width: 150px;
  height: 150px;
  padding: 4px;
}

.profile-img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.profile-btn {
  align-self: baseline;
}

/* pages */
.mission-img {
  height: 90px;
  width: 90px;
}
.mission-img img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.upload-img-wrap {
  padding: 5px;
  max-height: 150px;
  max-width: 150px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* transition: all 0.1s ease; */
  overflow: hidden;
}
.upload-img-wrap:hover {
  border: 1px solid #2b3990;
}
.upload-img-wrap img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.delete-btn {
  transition: all 0.3s ease-in-out;
}
.delete-btn:hover {
  scale: 1.1;
}
.modal-image-wrap {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccc;
  border-radius: 5px;
  padding: 2px;
}
.modal-image-wrap img {
  max-height: 100%;
  max-width: 100%;
  border-radius: 5px;
}
.notification-dot::after {
  content: "";
  height: 8px;
  width: 8px;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  background-color: yellowgreen;
  position: absolute;
  box-shadow: 0 0 2px 1px rgba(154, 205, 50, 0.5);
}

.custom-upload {
  width: 100% !important;
  height: 37vh !important;
}

.custom-upload .ant-upload-select {
  width: 100% !important;
  height: 37vh !important;
}

/* Media query for screens less than 991px */
@media (max-width: 991px) {
  .responsive-row .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mb-sm-3 {
    margin-bottom: 10px;
  }
  .image-upload-input-wrap input {
    width: 99% !important;
    margin-bottom: 5px;
  }
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-tabs .ant-tabs-tabpane {
    height: 32vh;
  }
  .full-scr-icon {
    display: none;
  }
}

.notification-icon {
  font-size: 18px;
}
.export-icon-wrap:hover .export-icon {
  scale: 1.1;
}
.pro-image img {
  max-height: 150px;
  object-fit: cover;
}
@media (max-width: 991px) {
  .nav-academic {
    display: none;
  }
  .ms-3-r {
    margin-left: 10px;
  }
}
@media (max-width: 768px) {
  :where(.css-dev-only-do-not-override-1gwfwyx).ant-tabs .ant-tabs-tabpane {
    height: 25vh;
  }
  .nav-user-img {
    height: 30px;
    width: 30px;
    padding: 2px;
  }

  .notification-icon {
    font-size: 16px;
  }
  .border-b-r {
    padding-bottom: 8px;
    /* padding-top: 8px; */
    border-bottom: 1px solid #ccc;
  }
  .g-3-r {
    gap: 5px;
  }
}
@media (max-width: 600px) {
  .mb-3-r {
    margin-bottom: 8px;
  }
  .w-200 {
    width: 100%;
  }
  .w-100-r {
    width: 100%;
  }
}
